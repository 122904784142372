import React, { useState, useRef } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ogImage from "../../../images/seo/side-navigation/side-navigation.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

//Hero
import SideNavigationHeroComponent from "../../../library/components/side-navigation/react/_preview/SideNavigationHeroComponent"

// Preview
import SideNavigationBasicPreview from "../../../library/components/side-navigation/react/_preview/basic"
import SideNavigationSeparatorPreview from "../../../library/components/side-navigation/react/_preview/separator"
import SideNavigationSearchBarPreview from "../../../library/components/side-navigation/react/_preview/search_bar"
import SideNavigationUserProfilePreview from "../../../library/components/side-navigation/react/_preview/user_profile"
import SideNavigationUserContactsPreview from "../../../library/components/side-navigation/react/_preview/user_contacts"

// All
const SideNavigationBasicJsx = require("!!raw-loader!../../../library/components/side-navigation/react/basic.jsx")
const SideNavigationBasicHTML = require("!!raw-loader!../../../library/components/side-navigation/html/basic.html")

const SideNavigationSeparatorJsx = require("!!raw-loader!../../../library/components/side-navigation/react/separator.jsx")
const SideNavigationSeparatorHTML = require("!!raw-loader!../../../library/components/side-navigation/html/separator.html")

const SideNavigationSearchBarJsx = require("!!raw-loader!../../../library/components/side-navigation/react/search_bar.jsx")
const SideNavigationSearchBarHTML = require("!!raw-loader!../../../library/components/side-navigation/html/search_bar.html")

const SideNavigationUserProfileJsx = require("!!raw-loader!../../../library/components/side-navigation/react/user_profile.jsx")
const SideNavigationUserProfileHTML = require("!!raw-loader!../../../library/components/side-navigation/html/user_profile.html")

const SideNavigationUserContactsJsx = require("!!raw-loader!../../../library/components/side-navigation/react/user_contacts.jsx")
const SideNavigationUserContactsHTML = require("!!raw-loader!../../../library/components/side-navigation/html/user_contacts.html")

const SideNavigationPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "SideNavigationBasic",
      title: "Basic",
      active_tab: 1,
    },
    {
      component_name: "SideNavigationSeparator",
      title: "With separator",
      active_tab: 1,
    },
    {
      component_name: "SideNavigationSearchBar",
      title: "Search Bar & Alert",
      active_tab: 1,
    },
    {
      component_name: "SideNavigationUserProfile",
      title: "User sidenavigation",
      active_tab: 1,
    },
    {
      component_name: "SideNavigationUserContacts",
      title: "With contact list",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Side Navigation Menus- WindUI Component Library"
        ogtitle="Tailwind CSS Side Navigation Menus- WindUI Component Library"
        description="Side navigation components are navigation elements, that are typically positioned on the left or right side of a screen. Built with Tailwind CSS by WindUI."
        ogdescription="Side navigation components are navigation elements, that are typically positioned on the left or right side of a screen. Built with Tailwind CSS by WindUI."
        url="components/side-navigation/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Side Navigation Menus, Side Navigation Menus, Side Navigation Menu Components, Side Navigations, Side Navigation, Side Navigation Components, sidebar, side menu, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Side Navigation</h1>
          <p>
            {" "}
            The side navigation component (often referred to as a "sidebar" or
            "side menu") is a user interface element that is typically
            positioned on the side of a webpage or application screen, such as
            the left or right side. It is used to provide navigation and access
            to various sections, features, or pages of the application.
          </p>

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="relative isolate flex h-[53.125rem] w-full flex-col justify-center overflow-hidden rounded-xl bg-wuiSlate-100 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
              <SideNavigationHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Basic side navigation menu */}
          <h3 id="SideNavigationBasic">
            Basic side navigation menu{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="SideNavigationBasic"
            HtmlComponent={SideNavigationBasicHTML.default}
            JsxComponent={SideNavigationBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="relative isolate flex h-[53.125rem] w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-100"
            >
              <SideNavigationBasicPreview
                SideNavigationBasic={
                  activeTabs[0].active_tab === 1
                    ? SideNavigationBasicHTML.default
                    : SideNavigationBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Side navigation with separator */}
          <h3 id="SideNavigationSeparator">
            Side Navigation menu with separator and multiple menu groups{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="SideNavigationSeparator"
            HtmlComponent={SideNavigationSeparatorHTML.default}
            JsxComponent={SideNavigationSeparatorJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="relative isolate flex h-[53.125rem] w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-100"
            >
              <SideNavigationSeparatorPreview
                SideNavigationSeparator={
                  activeTabs[1].active_tab === 1
                    ? SideNavigationSeparatorHTML.default
                    : SideNavigationSeparatorJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Side navigation menu with search bar */}
          <h3 id="SideNavigationSearchBar">
            Side navigation menu with search bar and alert message{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="SideNavigationSearchBar"
            HtmlComponent={SideNavigationSearchBarHTML.default}
            JsxComponent={SideNavigationSearchBarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="relative isolate flex h-[53.125rem] w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-100"
            >
              <SideNavigationSearchBarPreview
                SideNavigationSearchBar={
                  activeTabs[2].active_tab === 1
                    ? SideNavigationSearchBarHTML.default
                    : SideNavigationSearchBarJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Side navigation menu with user profile and alert message */}
          <h3 id="SideNavigationUserProfile">
            Side navigation menu with user profile and alert message{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="SideNavigationUserProfile"
            HtmlComponent={SideNavigationUserProfileHTML.default}
            JsxComponent={SideNavigationUserProfileJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="relative isolate flex h-[53.125rem] w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-100"
            >
              <SideNavigationUserProfilePreview
                SideNavigationUserProfile={
                  activeTabs[3].active_tab === 1
                    ? SideNavigationUserProfileHTML.default
                    : SideNavigationUserProfileJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/*  Side navigation menu with user profile and user contacts */}
          <h3 id="SideNavigationUserContacts">
            Side navigation menu with user profile and contact list{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="SideNavigationUserContacts"
            HtmlComponent={SideNavigationUserContactsHTML.default}
            JsxComponent={SideNavigationUserContactsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="relative isolate flex h-[900px] w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-100"
            >
              <SideNavigationUserContactsPreview
                SideNavigationUserContacts={
                  activeTabs[4].active_tab === 1
                    ? SideNavigationUserContactsHTML.default
                    : SideNavigationUserContactsJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Side navigation is positioned on the side of a screen to help users
            navigate an application's sections and features efficiently. They
            save space, offer clear labeling, and enhance user experience,
            especially in mobile design. The Side navigation remains open on
            bigger screens but auto hides on smaller screens and is then
            activated by a burger button.
          </p>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            The side navigation main is implemented with an{" "}
            <code>&lt;aside&gt;</code> html element with the following
            characteristics:
          </p>
          <ul>
            <li>
              <strong>Dimensions:</strong> The side navigations spans to the
              full height of the viewport and its width is 18rem (288px).
            </li>
            <li>
              <strong>Mobile trigger:</strong> On small screens a button is
              required to allow the side navigation to be opened and closed.
            </li>
            <li>
              <strong>Backdrop:</strong> When the side navigation is open on
              small screens a backdrops cover the content to allow the user to
              close the sidenavigation when its clicked.
            </li>
          </ul>
          <p>A navbar consists of the following main components:</p>
          <ul>
            <li>
              <strong>Header:</strong> the top part of the sidenavigation that
              can include the logo, user profile, or a search bar.
            </li>
            <li>
              <strong>Navigation links:</strong> a list that contains links to
              your main pages. It uses a <code>&lt;nav&gt;</code> element that
              includes a list for the links.
            </li>
            <li>
              <strong>Footer: </strong> It can contain actions (like a "logout"
              link) or even alerts for the user.
            </li>
          </ul>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              The navigation links wrapper should have a{" "}
              <code>role=&quot;navigation&quot;</code> (not required if a native{" "}
              <code>&lt;nav&gt;</code> is used.)
            </li>
            <li>
              The trigger button has an <code>aria-expanded</code> role with a
              true/false value, that allows vocalizing menu states (open or
              closed).
            </li>
            <li>
              If a user is on a page that is within the navigation menu the{" "}
              <code>aria-current=&quot;page&quot;</code> attribute should be
              assigned to the item corresponding to the active page.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default SideNavigationPage
